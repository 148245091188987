@media (min-width: 1024px)and (max-width: 1311px){.tlg-hidden-md {
  display: none !important;
}}@media (min-width: 1024px){:root {
  --headerHeightDesktop: 0px;
  --headerHeight: var(--headerHeightDesktop);
  --sizeOtherStickyElement: 0px;
  --sticky: calc(var(--headerHeight) + var(--sizeOtherStickyElement));
  --sizeOtherStickyBottomElement: 0px;
  --sticky-bottom: calc(0px - var(--sizeOtherStickyBottomElement));
}

.tlg-sticky {
  position: sticky;
  z-index: var(--sticky-z-index, 5);
  top: var(--sticky);
}

.tlg-sticky-bottom {
  position: sticky;
  z-index: var(--sticky-z-index, 5);
  bottom: var(--sticky-bottom);
}

:root {
  --container: 94.8rem;
  --padding-container: 3rem;
}

.tlg-grid {
  --grid-gap: 2.4rem;
}

.tlg-col-md-1 {
  --grid-col: 1;
}

.tlg-offset-col-md-1 {
  --grid-offset-col: 1;
}

.tlg-col-md-2 {
  --grid-col: 2;
}

.tlg-offset-col-md-2 {
  --grid-offset-col: 2;
}

.tlg-col-md-3 {
  --grid-col: 3;
}

.tlg-offset-col-md-3 {
  --grid-offset-col: 3;
}

.tlg-col-md-4 {
  --grid-col: 4;
}

.tlg-offset-col-md-4 {
  --grid-offset-col: 4;
}

.tlg-col-md-5 {
  --grid-col: 5;
}

.tlg-offset-col-md-5 {
  --grid-offset-col: 5;
}

.tlg-col-md-6 {
  --grid-col: 6;
}

.tlg-offset-col-md-6 {
  --grid-offset-col: 6;
}

.tlg-col-md-7 {
  --grid-col: 7;
}

.tlg-offset-col-md-7 {
  --grid-offset-col: 7;
}

.tlg-col-md-8 {
  --grid-col: 8;
}

.tlg-offset-col-md-8 {
  --grid-offset-col: 8;
}

.tlg-col-md-9 {
  --grid-col: 9;
}

.tlg-offset-col-md-9 {
  --grid-offset-col: 9;
}

.tlg-col-md-10 {
  --grid-col: 10;
}

.tlg-offset-col-md-10 {
  --grid-offset-col: 10;
}

.tlg-col-md-11 {
  --grid-col: 11;
}

.tlg-offset-col-md-11 {
  --grid-offset-col: 11;
}

.tlg-col-md-12 {
  --grid-col: 12;
}

.tlg-offset-col-md-12 {
  --grid-offset-col: 12;
}

.tlg-false-btn {
  position: absolute;
  top: 0;
  z-index: -1;
}

.tlg-false-btn [class*=tlg-ico] {
  color: #fff;
  background-color: rgba(255,255,255,.4);
  border: 0;
}

.tlg-false-btn:hover [class*=tlg-ico] {
  color: #fff;
  background-color: rgba(255,255,255,.4);
}

.tlg-false-btn-left {
  left: 0;
}

.tlg-false-btn-right {
  right: 0;
}

.tlg-anchor {
  position: relative;
  display: block;
  max-width: calc(var(--columnLeftSize) - 12rem);
  margin-bottom: 1.2rem;
  font-size: 1.8rem;
  padding-left: 3.9rem;
  transition: opacity .4s,font-size .4s;
}

.tlg-anchor:before,
.tlg-anchor:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 1.35rem;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background-color: #fff;
  transition: width .4s,height .4s,top .4s,left .4s;
}

.tlg-anchor:before {
  width: 6px;
  height: 6px;
  opacity: .4;
}

.tlg-anchor:after {
  width: 0;
  height: 0;
  top: 51%;
  left: 1.425rem;
}

.tlg-anchor:hover {
  opacity: 1;
}

.tlg-anchor.tlg-anchor-active {
  font-size: 2.4rem;
}

.tlg-anchor.tlg-anchor-active:before {
  width: 27px;
  height: 27px;
}

.tlg-anchor.tlg-anchor-active:after {
  width: 6px;
  height: 6px;
}

.tlg-ka-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 6rem;
  height: 5rem;
}

.tlg-footer-nav li {
  display: inline-block;
  margin-left: 3rem;
}

.tlg-ka-go-premium {
  margin-bottom: 1.6rem;
  color: #fff;
}

.tlg-ka-go-premium .tlg-ico-arrow-right {
  background-color: #fff;
}

.tlg-ka-go-premium .tlg-underline {
  display: block;
  color: #f9d696;
}

.tlg-ka-header {
  padding: 1.6rem 6rem;
  height: 8.2rem;
}

.tlg-ka-home main {
  height: calc(100vh - 5rem);
  overflow: hidden;
}

[id*=anchor-1]:checked~.tlg-sections-home {
  top: calc(-100vh * 0);
}

[id*=anchor-2]:checked~.tlg-sections-home {
  top: calc(-100vh * 1);
}

[id*=anchor-3]:checked~.tlg-sections-home {
  top: calc(-100vh * 2);
}

[id*=anchor-4]:checked~.tlg-sections-home {
  top: calc(-100vh * 3);
}

[id*=anchor-5]:checked~.tlg-sections-home {
  top: calc(-100vh * 4);
}

[id*=anchor-6]:checked~.tlg-sections-home {
  top: calc(-100vh * 5);
}

.tlg-sections-home {
  position: fixed;
  transition: top .4s;
}

.tlg-section-home {
  --sizeCardSelected: 32rem;
  --sizeCardNow: 64vh;
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: #02142b;
}

.tlg-section-home .tlg-cards {
  position: absolute;
  bottom: 21vh;
  left: var(--columnLeftSize);
  transition: left .6s;
}

.tlg-section-home .tlg-cards.tlg-cards-nows {
  bottom: 14vh;
}

.tlg-section-home .tlg-cards-events,
.tlg-section-home .tlg-cards-games {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: auto;
  grid-gap: 0 2.4rem;
  align-items: end;
}

.tlg-section-home .tlg-event,
.tlg-section-home .tlg-game {
  width: 29rem;
  transition: width .6s,opacity .6s;
  opacity: 0;
}

.tlg-section-home-content {
  position: absolute;
  left: var(--columnLeftSize);
  top: 50%;
  max-width: 46vw;
  transform: translateY(-50%);
}

.tlg-section-home-button {
  display: flex;
  position: absolute;
  bottom: 11vh;
  left: calc(var(--columnLeftSize) + 18rem);
  gap: 1.6rem;
  width: 8.8rem;
  transform: translateX(-50%);
}

.tlg-section-home-button label {
  display: none;
  margin-bottom: 0;
}

.tlg-section-home-uri {
  position: absolute;
  bottom: 11vh;
  left: calc(var(--columnLeftSize) + 25rem);
  color: #fff;
  text-decoration: underline;
}

[id*=card-1]:checked~.tlg-section-home .tlg-section-home-button {
  justify-content: end;
}

.tlg-section-home-figure:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(180deg, #061A2E 0%, rgba(6, 26, 46, 0) 100%);
}

.tlg-section-home-figure img {
  position: absolute;
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
  opacity: 0;
  transition: opacity .6s;
  filter: blur(30px) brightness(0.6);
}

[id*=card-1]:checked~.tlg-section-home .tlg-section-home-figure img:nth-child(1) {
  opacity: 1;
}

[id*=card-1]:checked~.tlg-section-home .tlg-cards {
  left: calc(var(--columnLeftSize) - 31.4rem*(1 - 1));
}

[id*=card-1]:checked~.tlg-section-home .tlg-card:nth-child(1) {
  width: var(--sizeCardSelected);
  opacity: 1;
}

[id*=card-1]:checked~.tlg-section-home .tlg-card:nth-child(1).tlg-now {
  width: var(--sizeCardNow);
}

[id*=card-1]:checked~.tlg-section-home .tlg-card:nth-child(1).tlg-now .tlg-card-title {
  margin-bottom: 1.6rem;
  font-size: 2.6rem;
  -webkit-line-clamp: 2;
  height: 6.2rem;
}

[id*=card-1]:checked~.tlg-section-home .tlg-card:nth-child(1)~.tlg-card {
  opacity: 1;
}

[id*=card-1]:checked~.tlg-section-home [for*=card-0] {
  display: block;
  transform: rotate(180deg);
}

[id*=card-1]:checked~.tlg-section-home [for*=card-2] {
  display: block;
}

[id*=card-2]:checked~.tlg-section-home .tlg-section-home-figure img:nth-child(2) {
  opacity: 1;
}

[id*=card-2]:checked~.tlg-section-home .tlg-cards {
  left: calc(var(--columnLeftSize) - 31.4rem*(2 - 1));
}

[id*=card-2]:checked~.tlg-section-home .tlg-card:nth-child(2) {
  width: var(--sizeCardSelected);
  opacity: 1;
}

[id*=card-2]:checked~.tlg-section-home .tlg-card:nth-child(2).tlg-now {
  width: var(--sizeCardNow);
}

[id*=card-2]:checked~.tlg-section-home .tlg-card:nth-child(2).tlg-now .tlg-card-title {
  margin-bottom: 1.6rem;
  font-size: 2.6rem;
  -webkit-line-clamp: 2;
  height: 6.2rem;
}

[id*=card-2]:checked~.tlg-section-home .tlg-card:nth-child(2)~.tlg-card {
  opacity: 1;
}

[id*=card-2]:checked~.tlg-section-home [for*=card-1] {
  display: block;
  transform: rotate(180deg);
}

[id*=card-2]:checked~.tlg-section-home [for*=card-3] {
  display: block;
}

[id*=card-3]:checked~.tlg-section-home .tlg-section-home-figure img:nth-child(3) {
  opacity: 1;
}

[id*=card-3]:checked~.tlg-section-home .tlg-cards {
  left: calc(var(--columnLeftSize) - 31.4rem*(3 - 1));
}

[id*=card-3]:checked~.tlg-section-home .tlg-card:nth-child(3) {
  width: var(--sizeCardSelected);
  opacity: 1;
}

[id*=card-3]:checked~.tlg-section-home .tlg-card:nth-child(3).tlg-now {
  width: var(--sizeCardNow);
}

[id*=card-3]:checked~.tlg-section-home .tlg-card:nth-child(3).tlg-now .tlg-card-title {
  margin-bottom: 1.6rem;
  font-size: 2.6rem;
  -webkit-line-clamp: 2;
  height: 6.2rem;
}

[id*=card-3]:checked~.tlg-section-home .tlg-card:nth-child(3)~.tlg-card {
  opacity: 1;
}

[id*=card-3]:checked~.tlg-section-home [for*=card-2] {
  display: block;
  transform: rotate(180deg);
}

[id*=card-3]:checked~.tlg-section-home [for*=card-4] {
  display: block;
}

[id*=card-4]:checked~.tlg-section-home .tlg-section-home-figure img:nth-child(4) {
  opacity: 1;
}

[id*=card-4]:checked~.tlg-section-home .tlg-cards {
  left: calc(var(--columnLeftSize) - 31.4rem*(4 - 1));
}

[id*=card-4]:checked~.tlg-section-home .tlg-card:nth-child(4) {
  width: var(--sizeCardSelected);
  opacity: 1;
}

[id*=card-4]:checked~.tlg-section-home .tlg-card:nth-child(4).tlg-now {
  width: var(--sizeCardNow);
}

[id*=card-4]:checked~.tlg-section-home .tlg-card:nth-child(4).tlg-now .tlg-card-title {
  margin-bottom: 1.6rem;
  font-size: 2.6rem;
  -webkit-line-clamp: 2;
  height: 6.2rem;
}

[id*=card-4]:checked~.tlg-section-home .tlg-card:nth-child(4)~.tlg-card {
  opacity: 1;
}

[id*=card-4]:checked~.tlg-section-home [for*=card-3] {
  display: block;
  transform: rotate(180deg);
}

[id*=card-4]:checked~.tlg-section-home [for*=card-5] {
  display: block;
}

[id*=card-5]:checked~.tlg-section-home .tlg-section-home-figure img:nth-child(5) {
  opacity: 1;
}

[id*=card-5]:checked~.tlg-section-home .tlg-cards {
  left: calc(var(--columnLeftSize) - 31.4rem*(5 - 1));
}

[id*=card-5]:checked~.tlg-section-home .tlg-card:nth-child(5) {
  width: var(--sizeCardSelected);
  opacity: 1;
}

[id*=card-5]:checked~.tlg-section-home .tlg-card:nth-child(5).tlg-now {
  width: var(--sizeCardNow);
}

[id*=card-5]:checked~.tlg-section-home .tlg-card:nth-child(5).tlg-now .tlg-card-title {
  margin-bottom: 1.6rem;
  font-size: 2.6rem;
  -webkit-line-clamp: 2;
  height: 6.2rem;
}

[id*=card-5]:checked~.tlg-section-home .tlg-card:nth-child(5)~.tlg-card {
  opacity: 1;
}

[id*=card-5]:checked~.tlg-section-home [for*=card-4] {
  display: block;
  transform: rotate(180deg);
}

[id*=card-5]:checked~.tlg-section-home [for*=card-6] {
  display: block;
}

#en-ce-moment .tlg-section-home-button {
  display: none !important;
}

.tlg-ka-home {
  --columnLeftSize: 50vw;
}

.tlg-ka-home .tlg-ka-header {
  position: fixed;
}

.tlg-home-message {
  position: fixed;
  top: 0;
  left: 0;
  width: var(--columnLeftSize);
  padding: 9.2rem 6rem 0;
  z-index: 1;
}

.tlg-anchors {
  position: fixed;
  top: calc((100vh - 26rem)/2 + 26rem);
  left: 0;
  width: var(--columnLeftSize);
  padding: 0 6rem;
  z-index: 1;
  transform: translateY(-50%);
}

.tlg-ka-title-home {
  margin-bottom: 0;
  font-size: 2.4rem;
}

.tlg-ka-subtitle-home {
  margin-bottom: 2.4rem;
}

.tlg-detail-header {
  padding-top: 14rem;
}

.tlg-detail-participation {
  padding-bottom: 8rem;
}

.tlg-base-header {
  padding-top: 18rem;
}

.tlg-base-content {
  min-height: calc(100vh - 41rem);
  --grid-gap: 2.4rem 5.6rem;
}

.tlg-error {
  padding-top: 14rem;
  min-height: calc(100vh - 12rem);
}}